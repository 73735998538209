import book from './images/book.jpg';
import cover from './images/cover.jpg';
import kindle from './images/kindle.jpg';
import compilado from './images/compilado.png';

const items = [
  {
    image: compilado,
    title: "O Compilado de Tirinhas",
    description: "Reúne as principais tirinhas da autora Lara Rojas em um único livro!",
    price: 55,
    link: "https://socorroeupenso.lojavirtualnuvem.com.br/produtos/o-compilado-de-tirinhas/"
  },
  /*
  {
    image: cover,
    title: "Capa dura",
    description: "Livro Expresso Fundo do Poço",
    price: 45,
    link: "https://socorroeupenso.lojavirtualnuvem.com.br/produtos/capa-dura-livro-expresso-fundo-do-poco/"
  },
  {
    image: book,
    title: "Capa mole",
    description: "Livro Expresso Fundo do Poço",
    price: 27,
    link: "https://socorroeupenso.lojavirtualnuvem.com.br/produtos/capa-mole-livro-expresso-fundo-do-poco/"
  },
  */
  {
    image: kindle,
    title: "Expresso Fundo do Poço",
    description: "Livro Kindle",
    price: 18.04,
    link: "https://www.amazon.com.br/dp/B0C4QJXBRP"
  },
]

function App() {
  return (
    <div className='flex flex-col items-center mb-10'>
      <p
        className={
          'w-full shadow-lg grid place-items-center ' +
          'title text-3xl h-20 md:text-4xl md:h-28 ' +
          'bg-gradient-to-br from-pink-100 to-pink-400'
        }
      >
        SOCORRO EU PENSO
      </p>
      <div>
        <p className='text-2xl mt-12 mb-10 md:text-3xl md:mt-16 md:mb-12'>
          Compre nossos produtos:
        </p>
        <div
          className={
            'flex flex-col space-y-10 ' +
            'md:flex-row md:space-y-0 md:space-x-20 md:mx-6'
          }
        >
          {items.map(item => {
            var formattedPrice = item.price.toLocaleString(
              "pt-BR", { minimumFractionDigits: 2});
            return (
              <a
                className='flex-1 flex flex-col items-center w-72'
                href={item.link}
              >
                <img
                  className={
                    'max-h-52 shadow-lg hover:shadow-xl hover:scale-105 ' +
                    'transition ease-in-out duration-300'
                  }
                  src={item.image}
                  alt={item.title}
                />
                <p className='font-black text-2xl mt-6'>{item.title}</p>
                <p className='text-center mt-1'>{item.description}</p>
                <p className='text-cyan-600 mt-1'>{`R$${formattedPrice}`}</p>
              </a>
            );
          })}
        </div>
      </div>
      {/*
      <a
        className={
          'rounded-xl shadow-lg hover:shadow-xl hover:scale-110 ' +
          'transition ease-in-out duration-300 ' +
          'font-black text-sm md:text-xl ' +
          'mt-12 px-5 py-3 ' +
          'bg-gradient-to-br from-pink-100 to-pink-400'
        }
        href='https://docs.google.com/forms/d/1jwiBB1Y3FmLEEP-yMqPNi9ZhBF3vQJXvv7w0iWNxsNA/edit'
      >
        <p>INSCREVA-SE NAS RODAS DE CONVERSA</p>
      </a>
      */}
    </div>
  );
}

export default App;
